import MediaAsset from './MediaAsset'
import ID from './ID'

export default class PlanDashboard {
  constructor(
    public id: ID = new ID(),
    public name: string = '',
    public title: string = '',
    public embedHtml: string = '',
    public asset: MediaAsset = new MediaAsset(),
    public isArchived: boolean = false,
    public plan: ID = new ID()
  ) {}

  public get key() {
    return 'plan-dashboard-' + this.id.intID.toString()
  }

  public static fromResponseObject(obj): PlanDashboard {
    if (!obj) return new PlanDashboard()
    return new PlanDashboard(
      ID.fromResponseObject(obj.id, 'plan_dashboards'),
      obj.name,
      obj.title,
      obj.embedHtml,
      MediaAsset.fromResponseObject(obj.asset),
      obj.isArchived,
      ID.fromResponseObject(obj.plan, 'plans'),
    )
  }

  public forRequestObject() {
    return {
      id: this.id.intID == 0 ? null : this.id.apiID,
      name: this.name,
      title: this.title,
      embedHtml: this.embedHtml,
      asset: this.asset.id.intID == 0 ? null : this.asset.id.apiID,
      isArchived: this.isArchived,
      plan: this.plan.apiID,
    }
  }
}
