<template>
  <div class="view-wrapper channels-view">
    <ViewHeader :title="$store.getters.currentPlan.name"></ViewHeader>
    <ViewMain>
      <div class="p-d-flex p-flex-column p-jc-between" style="height: 100%;">
        <div class="dashboard-wrapper">
          <h2>Plan Dashboard</h2>
          <div v-if="dashboardHTML" class="p-d-flex">
            <div class="embed-wrapper">
              <ProgressSpinner v-if="shouldShowProgressSpinner" strokeWidth="3" class="dashboard-progress-spinner" />
              <div class="embed-container" v-html="dashboardHTML"></div>
            </div>
          </div>
          <div v-else>
            <h3>No dashboard available.</h3>
          </div>
        </div>
        <div class="update-dashboard-container">
          <h3>Update Dashboard Embed Code</h3>
          <TextArea
            id="newHTMLEmbed"
            v-model="currentPlan.dashboardEmbedHtml"
            placeholder="HTML Embed Code"
            autocapitalize="none"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
          ></TextArea>
          <Button 
            label="Update"
            icon="pi pi-plus-circle"
            @click="() => updateDashboardEmbedHtml()" 
          ></Button>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import Button from 'primevue/button';
import TextArea from 'primevue/textarea'
import { AlertMessageSeverity } from '@/models/AlertMessage';

Vue.component('Button', Button)
Vue.component('TextArea', TextArea)

export default Vue.extend({
  name: 'Dashboard',
  
  components: {
    ViewHeader,
    ViewMain,
  },
  data: () => {
    return {
      shouldShowProgressSpinner: false,
    }
  },
  computed: {
    currentPlan() {
      return this.$store.getters.currentPlan
    },
    dashboardHTML() {
      return this.$store.getters.currentPlan.dashboardEmbedHtml
    }
  },
  methods: {
    updateDashboardEmbedHtml() {
      if(!this.currentPlan.dashboardEmbedHtml || this.currentPlan.dashboardEmbedHtml === ''){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please enter dashboard embed code.',
        })
        return
      }

      this.$store.getters.services.plans.update(this.currentPlan).then(
        (returnedPlan) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Dashboard updated.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error updating dashboard.',
          })
        }
      )
    },
  }
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.update-dashboard-container {
  margin-top: 2rem;
  max-width: 20rem;
}
.embed-wrapper {
  position: relative;

  .dashboard-progress-spinner {
    position: absolute;
  }
  .embed-container {
    position: relative;
    z-index: 2;
  }
}
</style>
