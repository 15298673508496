<template>
  <div class="view-wrapper channels-view">
    <ViewHeader :title="$store.getters.currentPlan.name">
    </ViewHeader>
    <ViewMain>
      <div class="p-d-flex">
        <div class="list-column">
          <h2>Reports</h2>
          <DataTable 
            :value="currentPlan.dashboards"
            editMode="row"
            dataKey="id.intID"
            :editingRows.sync="dashboardEditingRows"
            @row-edit-init="onDashboardRowEditInit"
            @row-edit-save="onDashboardRowEditSave"
            >
            <Column field="title" header="Title">
              <template #body="slotProps">
                <span :class="{'is-archived': slotProps.data.isArchived}">
                  {{ slotProps.data.title }}<template v-if="slotProps.data.isArchived"> (Archived)</template>
                </span>
              </template>
              <template #editor>
                <InputText v-model="currentEditingDashboard.title" />
              </template>
            </Column>
            <Column
              :rowEditor="true"
              bodyStyle="text-align: right;"
            ></Column>
            <Column>
              <template #body="slotProps">
                <div class="p-d-flex p-jc-end">
                  <Button 
                    label="Open" 
                    icon="pi pi-arrow-circle-up" 
                    class="p-button-text"
                    @click="setCurrentDashboard(slotProps.data)"
                  ></Button>
                </div>
              </template>
            </Column>

            <template #loading>
              <h3>Loading reports...</h3>
            </template>
            <template #empty>
              <h3>No reports available.</h3>
            </template>
          </DataTable>
          <div class="add-dashboard-container">
            <h3>Add New Report</h3>
            <InputText
              id="newTitle"
              type="text"
              v-model="newDashboard.title"
              placeholder="Title"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
            />
            <TextArea
              id="newHTMLEmbed"
              v-model="newDashboard.embedHtml"
              placeholder="HTML Embed Code"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="false"
              autocomplete="off"
            ></TextArea>
            <Button 
              label="Add Report"
              icon="pi pi-plus-circle"
              @click="() => addDashboard()" 
            ></Button>
          </div>
        </div>
        <div class="dashboard-column">
          <div v-if="currentDashboard.title" class="p-d-flex">
            <h2 class="p-mr-2">{{ currentDashboard.title }}</h2>
            <div class="p-d-flex p-ml-auto">
              <div class="p-d-flex p-ai-center p-mr-4">
                <label for="switchArchive" class="p-mr-2">{{archiveLabel}}</label>
                <InputSwitch 
                  v-model="currentDashboard.isArchived" 
                  id="switchArchive" 
                  name="switchArchive" 
                  class="p-mr-auto" 
                  @input="toggleIsArchived" 
                />
              </div>
              <Button 
                class="p-button-text p-button-danger" 
                label="Delete" 
                icon="pi pi-trash" 
                @click="deleteCurrentDashboard"
              ></Button>
            </div>
          </div>
          <div class="embed-wrapper">
            <ProgressSpinner v-if="shouldShowProgressSpinner" strokeWidth="3" class="dashboard-progress-spinner" />
            <div class="embed-container" v-html="currentDashboard.embedHtml"></div>
          </div>
        </div>
      </div>
    </ViewMain>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ViewHeader from '@/components/ViewHeader.vue'
import ViewMain from '@/components/ViewMain.vue'
import PlanDashboard from '@/models/PlanDashboard';
import DataTable from 'primevue/datatable/DataTable';
import Column from 'primevue/column/Column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea'
import { AlertMessageSeverity } from '@/models/AlertMessage';


Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('TextArea', TextArea)

export default Vue.extend({
  name: 'Reports',
  
  components: {
    ViewHeader,
    ViewMain,
  },
  data: () => {
    return {
      currentDashboard: {} as PlanDashboard,
      newDashboard: {} as PlanDashboard,
      currentEditingDashboard: {} as PlanDashboard,
      dashboardEditingRows: [],
      archiveLabel: 'Archive',
      shouldShowProgressSpinner: false,
    }
  },
  computed: {
    currentPlan() {
      return this.$store.getters.currentPlan
    }
  },
  mounted: function(){
    this.resetNewDashboard()
    this.resetCurrentDashboard()
  },
  methods: {
    resetNewDashboard() {
      this.newDashboard = new PlanDashboard()
    },
    resetCurrentDashboard() {
      if(this.currentPlan.dashboards.length){
        this.setCurrentDashboard(this.currentPlan.dashboards[0])
      }else{
        this.currentDashboard = {} as PlanDashboard
        this.shouldShowProgressSpinner = false
      }
    },
    setCurrentDashboard(dashboard) {
      this.shouldShowProgressSpinner = true
      this.currentDashboard = dashboard as PlanDashboard
      this.currentDashboard.plan = this.currentPlan.id // Set plan ID because PlanDashboard object from Plan object does not include a Plan ID
      this.archiveLabel = this.currentDashboard.isArchived ? 'Unarchive' : 'Archive'
    },
    addDashboard() {
      if(!this.newDashboard.title || this.newDashboard.title === '' || !this.newDashboard.embedHtml || this.newDashboard.embedHtml === ''){
        Vue.prototype.$toast.add({
          severity: AlertMessageSeverity.warn,
          summary: 'Please enter a name and embed code for the new report.',
        })
        return
      }

      this.newDashboard.plan = this.currentPlan.id

      this.$store.getters.services.dashboards.create(this.newDashboard).then(
        (returnedNewDashboard) => {
          this.currentPlan.dashboards.push(returnedNewDashboard)
          this.resetNewDashboard()

          if(this.currentPlan.dashboards.length === 1){
            this.resetCurrentDashboard()
          }

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Report created.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error creating report.',
          })
        }
      )
    },
    toggleIsArchived(){
      this.$store.getters.services.dashboards.update(this.currentDashboard).then(
        (updatedDashboard) => {
          // Update dashboard in currentPlan
          const targetIndex = this.currentPlan.dashboards.findIndex((dashboard) => dashboard.id.intID == (updatedDashboard as PlanDashboard).id.intID)
          this.currentPlan.dashboards.splice(targetIndex ? targetIndex : 0, 1, updatedDashboard)

          // Update current dashboard
          this.setCurrentDashboard(updatedDashboard)

          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: this.currentDashboard.isArchived ? 'Report archived.' : 'Report unarchived.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: this.currentDashboard.isArchived ? 'Error unarchiving report.' : 'Error archiving report.',
          })
        }
      )
    },
    deleteCurrentDashboard(event) {
      this.$confirm.require({
        acceptLabel: 'YES',
        rejectLabel: 'NO',
        target: event.currentTarget,
        message: 'Are you sure you want to delete this report?',
        acceptClass: 'btn-delete-accept',
        accept: () => {
          this.$store.getters.services.dashboards.delete(this.currentDashboard.id).then(
            () => {
              // Remove this.currentDashboard from this.currentPlan.dashboards
              const targetIndex = this.currentPlan.dashboards.findIndex((dashboard) => dashboard.id.intID == this.currentDashboard.id.intID)
              this.currentPlan.dashboards.splice(targetIndex ? targetIndex : 0, 1)

              // Clear this.currentDashboard
              this.resetCurrentDashboard()
              
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.success,
                summary: 'Report deleted.',
                life: 3000,
              })
            },
            (error) => {
              Vue.prototype.$toast.add({
                severity: AlertMessageSeverity.error,
                summary: 'Error deleting report.',
              })
            }
          )
        },
        reject: () => {
          //Delete rejected
        }
      })
    },
    onDashboardRowEditInit(event){
      this.currentEditingDashboard.title = event.data.title
    },
    onDashboardRowEditSave(event){
      if (this.currentEditingDashboard.title === event.data.title) {
        return
      }

      this.$store.getters.services.dashboards.update(event.data as PlanDashboard).then(
        (dashboard) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.success,
            summary: 'Report updated.',
            life: 3000,
          })
        },
        (error) => {
          Vue.prototype.$toast.add({
            severity: AlertMessageSeverity.error,
            summary: 'Error updating report.',
          })
        }
      )
    }
  }
})
</script>

<style lang="scss">
@import '@/styles/_imports.scss';

.add-dashboard-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;

  input,
  textarea {
    width: 100%;
  }
}
.list-column {
  width: 20%;
  margin-right: 3rem;

  .is-archived {
    color: $nobel;
  }
}
.dashboard-column {
  width: 80%;
}
.embed-wrapper {
  position: relative;

  .dashboard-progress-spinner {
    position: absolute;
  }
  .embed-container {
    position: relative;
    z-index: 2;
  }
}
</style>
